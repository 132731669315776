import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import useFirebase from "../../context/firebase"
import { Container, Section } from "../global"
import AnchorLink from "react-anchor-link-smooth-scroll"

const GetStarted = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          linkToApp
        }
      }
    }
  `)
  return (
    <StyledSection>
      <GetStartedContainer>
        <GetStartedTitle>Be the first to get the beta</GetStartedTitle>
        <TryItLink href={data.site.siteMetadata.linkToApp}>
          Get early access
        </TryItLink>
        {/* <BetaSignUpForm /> */}
      </GetStartedContainer>
    </StyledSection>
  )
}

export default GetStarted

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`

const TryItLink = styled.a`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.primary.main};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 0px;
    background: ${props => props.theme.color.primary.dark};
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
