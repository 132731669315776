import React, { useEffect } from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
import Features from "../components/sections/features"
import Steps from "../components/sections/stepsToTranscript"
import Footer from "../components/sections/footer"
import GetStarted from "../components/sections/getstarted"
import useFirebase from "../context/firebase"

const IndexPage = () => {
  const firebase = useFirebase()
  useEffect(() => {
    if (firebase && firebase.analytics) {
      firebase.analytics.logEvent("visited_page")
    }
  }, [firebase])

  return (
    <Layout>
      <SEO title="Transcription made easy" />
      <Navigation />
      <Header />
      <Features />
      <Steps />
      <GetStarted />
      <Footer />
    </Layout>
  )
}

export default IndexPage
