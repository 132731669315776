import React from "react"
import styled from "styled-components"
import { navigate, useStaticQuery, graphql } from "gatsby"
import useFirebase from "../../context/firebase"

export const BetaSignUpForm = () => {
  const firebase = useFirebase()
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          linkToApp
        }
      }
    }
  `)

  const handleSubmit = e => {
    e.preventDefault()
    firebase.then(fb => fb.logEvent("beta_signup"))
    navigate(data.site.siteMetadata.linkToApp)
  }

  return (
    <>
      <HeaderForm>
        <HeaderButton onClick={handleSubmit}>Get Early Access</HeaderButton>
      </HeaderForm>
      {/* <FormSubtitle>
        Already have a beta account?{" "}
        <FormSubtitleLink to="/webapp/signin">Sign in</FormSubtitleLink>
      </FormSubtitle> */}
    </>
  )
}

const HeaderInputGroup = styled.div`
  width: 100%;
`

const ErrorMessage = styled.span`
  color: ${props => props.theme.color.danger.main};
`

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.black.regular};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.primary.light};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.color.primary.light} 0px 0px 0px
      1px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const HeaderButton = styled.button`
  transition: background-color 0.5s ease-out;
  background-color: ${props => props.theme.color.white.regular};
  font-weight: 800;
  font-size: 14px;
  color: ${props => props.theme.color.primary.main};
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 4px;
  padding: 0px 40px;
  border: 1px solid #fff;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 0px;
    background-color: ${props => props.theme.color.white.darker};
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
