import React from "react"
import styled from "styled-components"
import AutomationVideo from "../../videos/automation.mp4"
import GetStartedVideo from "../../videos/getstarted.mp4"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <SectionTitle>Smart and easy Audio Transcription</SectionTitle>
      <FeaturesGrid>
        <FeatureGroup>
          <FeatureVideo>
            <Video
              src={AutomationVideo}
              autoPlay
              loop
              type="video/mp4"
              playsInline
              muted
            />
          </FeatureVideo>
          <FeatureDescription>
            <FeatureGroupTitle id="automation">Automation</FeatureGroupTitle>
            <FeatureItemGroup>
              <FeatureItem>
                <FeatureTitle>Automatic Speaker Alteration</FeatureTitle>
                <FeatureText>
                  Configure the speakers and the editor will automatically
                  alterate without using manual intervention.
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <FeatureTitle>Intelligent Transcribe mode</FeatureTitle>
                <FeatureText>
                  Transcribe in flow, without shortcuts just listen and
                  transcribe as you go, the editor will stop when you write and
                  start right after.
                </FeatureText>
              </FeatureItem>
            </FeatureItemGroup>
          </FeatureDescription>
        </FeatureGroup>
        <FeatureGroup>
          <FeatureVideo>
            <Video
              src={GetStartedVideo}
              type="video/mp4"
              autoPlay
              loop
              playsInline
              muted
            />
          </FeatureVideo>
          <FeatureDescription>
            <FeatureGroupTitle id="quickstart">
              Get started immediately
            </FeatureGroupTitle>
            <FeatureItemGroup>
              <FeatureItem>
                <FeatureTitle>Your transcript in seconds</FeatureTitle>
                <FeatureText>
                  The intuitive design and clean user interface guarantees an
                  easy usage. Create new Transcripts in seconds.
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <FeatureTitle>Get a first draft</FeatureTitle>
                <FeatureText>
                  Get a first draft of you transcript using machine learning.
                  Afterwards you just need to correct but most of the work is
                  already done.
                </FeatureText>
              </FeatureItem>
            </FeatureItemGroup>
          </FeatureDescription>
        </FeatureGroup>
        <FeatureGroup>
          <FeatureVideo>
            <Video src={AutomationVideo} type="video/mp4" />
          </FeatureVideo>
          <FeatureDescription>
            <FeatureGroupTitle id="flow">Flow</FeatureGroupTitle>
            <FeatureItemGroup>
              <FeatureItem>
                <FeatureTitle>Shortcuts</FeatureTitle>
                <FeatureText>
                  Transcribe fast and concentrated without even touching your
                  mouse all from your fingertips. All you need to do is
                  transcribe. Autote deals with the rest.
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <FeatureTitle>Variable Pace</FeatureTitle>
                <FeatureText>
                  Transcribe in your own pace and save time by transcription
                  without repetition. You can set the playback speed of the
                  transcript to any value you like.
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <FeatureTitle>No Distraction</FeatureTitle>
                <FeatureText>
                  The editor is designed to keep distractions away and focus
                  completely on the transcript.
                </FeatureText>
              </FeatureItem>
            </FeatureItemGroup>
          </FeatureDescription>
        </FeatureGroup>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.black.regular};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 80px;
`

const FeatureGroup = styled.div`
  display: flex;
  flex-direction: row-reverse;
  &:nth-child(2) {
    flex-direction: row;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
  }
`

const FeatureItemGroup = styled.div`
  display: flex;
  align-content: space-between;
  flex-direction: column;
  @media (max-width: ${props =>
      props.theme.screen.md}) and (min-width: ${props =>
      props.theme.screen.sm}) {
    flex-direction: row;
  }
  @media (min-width: ${props => props.theme.screen.lg}) {
    flex: 1;
  }
`

const FeatureItem = styled.div`
  flex: 1;
  @media (max-width: ${props =>
      props.theme.screen.md}) and (min-width: ${props =>
      props.theme.screen.sm}) {
    margin: 0px 20px;
  }
`

const FeatureGroupTitle = styled.h3`
  color: ${props => props.theme.color.black.light};
  letter-spacing: 0px;
  margin-bottom: 10px;
  margin-top: 0px;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.black.light};
  letter-spacing: 0px;
  margin-bottom: 6px;
  margin-top: 10px;
`

const FeatureText = styled.p`
  color: ${props => props.theme.color.black.lightest};
`

const FeatureVideo = styled.div`
  @media (min-width: ${props => props.theme.screen.md}) {
    flex: 2;
    padding: 10px 20px;
  }
`

const FeatureDescription = styled.div`
  @media (min-width: ${props => props.theme.screen.md}) {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  }
`
const Video = styled.video`
  height: auto !important;
  width: 100% !important;
`
