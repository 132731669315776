import React from "react"
import styled from "styled-components"
import wavesSVG from "../../images/waves.svg"

import { Container } from "../global"
import { BetaSignUpForm } from "../common/betaSignupForm"

const Header = () => {
  return (
    <HeaderWrapper backgroundImage={wavesSVG} id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <h1>
              Transcription
              <br />
              made easy
            </h1>
            <h2>
              We're building the next generation transcription tool. Sign up to
              get early access.
            </h2>
            <BetaSignUpForm />
          </HeaderTextGroup>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.primary.main};
  padding: 160px 0 80px 0;
  position: relative;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center bottom;
  @media (max-width: ${props => props.theme.screen.md}) {
  }
`

const HeaderTextGroup = styled.div`
  margin: 0 0 40px 0;
  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.black.regular};
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`
