import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const STEPS = [
  {
    title: "Start a new project",
    media: "1-add-new-transcript.png",
    text: `Once you've logged in, you see an overview over all your trancsription
projects. To start a new transcription project for your interview,
simply click the plus icon. Afterwards a new page will open where you
can enter all the details of your transcript.`,
  },
  {
    title: `Upload your interview`,
    media: "2-upload-audio.png",
    text: `After you enter the page where you can describe your new transcript
      project, the first are area takes care of your interview. Simply drag
      & drop an audio file into the area or, click on the drop area to
      select a file from your file system. Currently .mp3 is supported, as
      it is the most common format for audio files, and most of the tools
      used for recording audio can export files to mp3.`,
  },
  {
    title: `Set the information of your project`,
    media: "3-set-information.png",
    text: `In the next section you can set the relevant information of your project. Set a name, which helps you remembering the interview afterwards. Also set the language of the interview, currently we support German, british English and american English. More languages will come soon. Those languages are only really relevant if you want to use the cloud trancsription, if you want to trancsribe by yourself, the language is not relevant and just helps you categorizing your trancsripts. Moreover set the number of speakers your interview has.`,
  },
  {
    title: `Cloud Transcription`,
    media: "4-use-cloud-stt.png",
    text: `After you set the relevant information and uploaded the audio file, you can also decide whether you want to use cloud transcription to create a first draft of your transcript. This will take a few minutes, but the advantage is that you already start with most of the text. You just need to go over the trancsript and correct what the machine got wrong. This is also very gentle to you arms and fingers.`,
  },
  {
    title: `Transribe!`,
    media: "5-press-transcribe.png",
    text: `Now your transcript is ready to be transcribed! You've uploaded the audio, named the trancsript and set some meta information. Also you decided for cloud transcription. 
    After you press transcribe, you be led back to the transcript overview, because we first process your transcript before you can enter the editor`,
  },
  {
    title: `Wait until transcript is ready`,
    media: "6-wait-until-processed.png",
    text: `Because we chose cloud transcription you cannot directly dive into your transcript. 
    If you don't choose this option, the previous step will lead you directly to the editor to start transcribing! 
    But for now we must wait a few minutes until the transcript is ready. Within the overview you see all your transcript projects, each with a name, the duration of the interview, the language and also the number of speakers involved.`,
  },
  {
    title: `Open Transcript`,
    media: "7-open-transcript.png",
    text: `After your transcript is ready, click on open to enter the transcript editor.`,
  },
  {
    title: `Name your speakers`,
    media: "8-rename-speakers.png",
    text: `The first thing you should do in the editor, is name the speakers. The card on the right helps you defining their names, and you will see the change immediately in the transcript.`,
  },
  {
    title: `Use the Editor to listen & correct`,
    media: "9-listen-correct.png",
    text: `Within the editor you have several options to work with the transcript. You can always set the playback speed to a comfortable pace in order for you to follow the interview and make corrections immediately. 
    Also you can activate the auto transcription mode. 
    We highly recommend to use this mode, it supports you in concentrating on the transcript and having your fingertips on the keyboard. 
    It will automatically stop once you start writing or correcting text, and when you finished it will continue with the playback. 
    Therefore you don't need to press pause and play all the time, as the system will take care of this by itself. Now you can just set the playback speed you want, and start listening. 
    If you want to jump within the transcript you can hover over a timestamp and jump to that position. Whenever you notice an error within the text, simply correct it, the playback will pause while you are editing and start right after you finished. All buttons you find in the editor also have a shortcut which you can find if you hover over the respective button.`,
  },
  {
    title: `Save your transcript`,
    media: "10-save-transcript.png",
    text: `Don't forget to save your transcript regularly in order to prevent losses in your work. Saving is a simple as clicking the save button in the top`,
  },
  {
    title: `Download your transcript for further analysis`,
    media: "11-download.png",
    text: `Maybe you want to download the trancsript to import it to another software for further analysis, if you just want to print it, you can print it directly from the editor using the Strg+P shortcut.`,
  },
  {
    title: `Done!`,
    media: undefined,
    text: `Now you are done and you can start with your next transcript project right away!`,
  },
]

const Steps = () => {
  let data = useStaticQuery(graphql`
    query StepPictures {
      allFile(filter: { sourceInstanceName: { eq: "steps" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 900) {
              src
              sizes
              srcSet
              base64
              aspectRatio
              originalName
            }
          }
        }
      }
    }
  `)
  const getFluidImage = id => {
    if (id !== "" || id !== undefined) {
      let image = data.allFile.nodes.find(
        (n, idx) => n.childImageSharp.fluid.originalName === id
      )
      if (image !== undefined) return image.childImageSharp.fluid
      return undefined
    }
  }
  return (
    <Section id="getstarted">
      <StyledContainer>
        <SectionTitle>Easy steps to transcribe an interview</SectionTitle>
        {STEPS.map((s, idx) => {
          let image = getFluidImage(s.media)
          return (
            <Step key={idx}>
              <StepTitle>{s.title}</StepTitle>
              {s.media !== undefined && image && (
                <Img alt={s.title} fluid={image}></Img>
              )}
              <StepText>{s.text}</StepText>
            </Step>
          )
        })}
      </StyledContainer>
    </Section>
  )
}

export default Steps

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.black.regular};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${props => props.theme.screen.md}) {
    width: 960px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
  }
`

const StepTitle = styled.h3`
  color: ${props => props.theme.color.black.light};
  letter-spacing: 0px;
  margin-bottom: 10px;
  margin-top: 0px;
`
const StepText = styled.p`
  color: ${props => props.theme.color.black.lightest};
  ${props => props.theme.font_size.regular};
  text-align: justify;
`
